.earning-section--dividends {
	.loader-container {
		display: flex;
		justify-content: center;
		padding: 10% 0px;
	}
}
.date-filters {
	position: relative;
	.calendar-container {
		position: absolute;
		width: 345px;
		top: 45px;
	}
	.input-item,
	.input-icon--calendar {
		cursor: pointer;
	}
}

.block-info__token-icon img,
.token-box__icon-1 {
	object-fit: cover;
	height: 100%;
}
.input-item--transfer {
	height: 59px;
	padding: 0 20px 0 20px;
	color: var(--text-four-color);
	border: none;
	font-weight: 600;
	font-size: 16px;
}

.token-block--biger .token-block__icon {
	height: 32px;
	border-radius: 50%;
	overflow: hidden;
}

.table--my-token-table {
	.token-line {
		justify-content: left;
	}
}

.hint-block:hover .tooltip {
	font-weight: 300;
	display: block;
}

.walletconnect-modal__header {
	img {
		width: 50%;
	}
	p {
		width: 50%;
	}
}

.block-content {
	.token-box-wrapper {
		justify-content: end;
	}
}
.dividents-btns .button--width {
	min-width: 148px;
}
// .earning-section--dividents .switch-box {
// 	justify-content: flex-end;
// }

@media screen and (max-width: 1100px) {
	.earning-block-wrapper {
		gap: 0 16px;
	}
}

@media screen and (max-width: 480px) {
	.token-box__name {
		font-size: 14px;
	}
	.earning-stats__item {
		width: 100%;
	}
}
@media screen and (max-width: 835px) {
	.switch-btns {
		justify-content: end;
	}
	.switch-btns--staking .switch-btns__item {
		padding: 0 15px;
	}
}
.card__qr-code {
	position: absolute;
	right: 72px;
	top: 55px;
	width: 90px;
}
.card-scan__type1 {
	position: relative;
}

.stat-and-chart {
	// height: auto;
	// padding: 24px;

	&__graph {
		flex: 0 1;
		canvas {
			max-width: 100%;
		}
		@media screen and (max-width: 1070px) {
			height: auto;
		}
	}
	&__top {
		margin-bottom: 30px;
	}
	&__procent {
		&.red {
			color: red;
			.stat-and-chart__procent-icon {
				transform: rotate(0deg);
				svg {
					stroke: red;
				}
			}
		}
	}
}

.wallet-block__procent {
	&.red {
		color: red;
		span {
			transform: rotate(0deg);
			svg {
				stroke: red;
			}
		}
	}
}

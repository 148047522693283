.react-tel-input .form-control{
    //border: none;
    height: 42px;;
    width: 100%;
}
// .react-tel-input .flag-dropdown{
//     background-color: white;
//    // border: none;
// }
.input-wrapper .custom-button{
    background-color: white;
    border-right: none !important;
}

.input-wrapper .custom-button::after{
    content: "";
    height: 31px;
    width: 1px;
    position: absolute;
    left: 51px;
    top: 5px;
    background-color: #eaeaef;
}

.react-tel-input .form-control{
   border-radius: 12px !important;
} 

.input-wrapper .custom-button .selected-flag .arrow{
    display: block !important;
  top:1px;
  width: 9px !important;
  height: 9px !important;
  border-top: 1px solid #000 !important;
  border-left: 1px solid #000 !important;
  transform: rotate(225deg);
  left: 26px !important;
}
.input-wrapper .custom-button .selected-flag .arrow.up{
    top: 50%;
    transform: rotate(45deg);
   border-bottom: none;
}

.authorization-form__group .input--error .input-item{
    border: 2px solid #eb5757 !important;
} 
.input--error .react-tel-input .flag-dropdown{
    border: 2px solid #eb5757 !important;
    border-right: none;
}
// .input--success .react-tel-input .flag-dropdown{
//     border-color:#27ae60 !important;
//     border-right: none;
// }
// .input--success .input-item{
//     border-color: #27ae60 !important;
// }
.input-wrapper.input--error{
 .form-control.input-item{
   border: 2px solid var(--ui-error-color) !important; 
 }
}
.input--disable .input-item {
    background: #EFF3FF;
    border: 1px solid #EAEAEF;
}
.input--disable {
    .input-wrapper .custom-button {
        background: #EFF3FF;
        border: 1px solid #EAEAEF;
    }
}
.react-datepicker {
	background: #eff3ff;
	box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
	border-radius: 14px;
	border-color: var(--main-color);
	padding: 8px;
	gap: 8px;
	font-family: inherit;

	&__month-container {
		float: none;
	}

	&__tab-loop {
		position: absolute;
		display: flex;
	}

	&__header {
		background-color: inherit;
		border-bottom: none;
		background: none;
	}

	&__navigation--previous {
		right: 0;
	}

	&__day {
		font-weight: 500;
		font-size: 13px;
		line-height: 22px;
		width: 24px;
		color: rgba(26, 32, 63, 1);
		span {
			position: relative;
			z-index: 10;
			display: inline-block;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 1px solid transparent;
		}
		&--selected {
			background-color: transparent;
			span {
				background: transparent;
				border-color: rgb(51, 91, 233);
			}
		}
		&--in-range:not(.react-datepicker__day--range-start),
		&--in-selecting-range:not(.react-datepicker__day--selecting-range-start) {
			position: relative;
			background: var(--main-color);
			color: #fff;
			&:not(:first-child):after {
				content: '';
				position: absolute;
				background: var(--main-color);
				width: 185%;
				height: 100%;
				right: 50%;
				top: 0;
			}
		}

		&--selecting-range-start,
		&--selecting-range-start.react-datepicker__day--in-selecting-range,
		&--range-start {
			span {
				background-color: #fff;
				border-color: var(--main-color);
				color: #000;
				z-index: 50;
			}
		}
		&--range-end {
			border-color: rgb(145, 167, 244);
			span {
				background-color: rgb(145, 167, 244);
			}
		}

		&--keyboard-selected:not(.react-datepicker__day--range-end) {
			background-color: transparent;
			span {
				border-color: var(--main-color);
			}
		}

		&--disabled,
		&--outside-month {
			color: #ccc;
		}
	}

	&__day-name {
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 12, 255px;
		color: #8e8ea9;
	}

	&__day-names,
	&__week {
		display: flex;
		justify-content: space-between;
	}

	&__triangle {
		display: none;
	}

	&__month {
		margin: 0;
	}

	@media (min-width: 685px) {
		flex-wrap: nowrap;
	}

	.dropdown {
		width: 100%;
		border-radius: 0 0 12px 12px;
		box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
		padding: 5px;
		button {
			padding: 8px 15px;

			transition: 0.2s;
			font-weight: 500;
			border-radius: 12px;
			width: 100%;
			&:hover {
				background: #f4f5fa;
			}
		}
	}
}

.react-datepicker__week {
	.react-datepicker__day,
	.react-datepicker__month-text,
	.react-datepicker__quarter-text,
	.react-datepicker__year-text {
		border-radius: 50%;
		&:hover {
			background-color: rgb(145, 167, 244);
		}
	}

	.react-datepicker__month-text--keyboard-selected,
	.react-datepicker__quarter-text--keyboard-selected,
	.react-datepicker__year-text--keyboard-selected {
		background-color: var(--main-color);
		color: #fff;
		border-radius: 50%;
		&:hover {
			background-color: rgb(145, 167, 244);
		}
	}

	.react-datepicker__day--selected,
	.react-datepicker__day--in-selecting-range,
	.react-datepicker__day--in-range,
	.react-datepicker__month-text--selected,
	.react-datepicker__month-text--in-selecting-range,
	.react-datepicker__month-text--in-range,
	.react-datepicker__quarter-text--selected,
	.react-datepicker__quarter-text--in-selecting-range,
	.react-datepicker__quarter-text--in-range,
	.react-datepicker__year-text--selected,
	.react-datepicker__year-text--in-selecting-range,
	.react-datepicker__year-text--in-range {
		border-radius: 50%;
		&:hover span {
			background-color: rgb(145, 167, 244);
		}
	}
}

.calendar-header {
	.select-month {
		font-weight: 600;
		font-size: 10px;
		line-height: 17px;
		color: var(--main-color);
		border: none;
		outline: none;
		max-width: 125px;
		padding: 8px;
	}
	.select-year {
		border: none;
		background: transparent;
		font-size: 1.2em;
		font-weight: bold;
		font-family: inherit;
		outline: none;
		padding: 0 10px;
		cursor: pointer;
		// -moz-appearance: none;
		// -webkit-appearance: none;
		// appearance: none;
	}
}

.select-wrapper {
	gap: 16px;
	position: relative;
}

.btn-wrapper {
	display: flex;
	padding: 0 5px;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	.h5 {
		font-size: 20px;
		line-height: 30px;
	}
	.arrow {
		border: 0.15rem solid #8e8ea9;
		width: 8px;
		height: 8px;
		background-color: inherit;
		transform: rotate(-45deg);
	}
	.left-arrow {
		border-bottom: none;
		border-right: none;
	}

	.right-arrow {
		border-top: none;
		border-left: none;
	}
}

.calendar {
	.react-datepicker {
		width: 100%;
		box-shadow: none;
		border-color: transparent;
	}
	.btn-wrapper .arrow {
		width: 10px;
		height: 10px;
	}
}

.popup--history {
	.popup.popup--calendar {
		position: absolute;
		top: 0;
		left: -2px;
		width: 102%;
		z-index: 99;
	}
}

.popup.popup--calendar {
	.calendar-top {
		margin-bottom: 0;
	}
	.calendar-input {
		padding: 4px 8px;
	}
}

.input-item--right-icon {
	padding-right: 30px;
}
.calendar-zindex{
	z-index: 100;
}
.swap-form {
	&__info-block {
		min-height: 24px;
	}

	&__exchange-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 5px;
	}

	&__exchange-rate-group {
		display: flex;
		align-items: center;
		column-gap: 5px;
	}

	&__exchange-label {
		font-weight: 500;
		font-size: 14px;
		line-height: 1.7;
		color: var(--text-four-color);
	}

	&__exchange-rate {
		font-weight: 600;
		font-size: 14px;
		color: var(--text-four-color);

		span {
			font-weight: 500;
		}
	}

	&__exchange-reverse-button {
		width: 18px;
		height: 18px;
	}
}

.swap-token-list {
	&__no-tokens {
		font-weight: 500;
		font-size: 12px;
		color: var(--text-secondary-color);
	}

	.balance-list {
		padding-right: 8px;
		height: 264px;
		overflow-y: auto;

		scrollbar-color: #b6b6b6 transparent;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 5px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 3px;
			background-color: #b6b6b6;
		}

		&--centered {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
.table--pools{
	.body--position{
		position: relative;
	}
}
.pool--name-size{
	font-size: 16px;
}
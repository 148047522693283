.body-fixed {
	position: fixed;
	width: 100vw;
}
.avatar-img {
	height: 100%;
	object-fit: cover;
	background: #fff;
}
.avatar--round {
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}
.login-info {
	.select-block {
		margin-bottom: 18px;
	}
}
.select--pointer {
	cursor: none;
}
.select-block__name {
	margin-bottom: 4px;
	font-size: 12px;
	font-weight: 600;
}
.select-block.input--error {
	.select {
		border: 2px solid var(--ui-error-color) !important;
	}
}

.earning--date-period{
	
}

.select-block {
	.select__drop-item {
		padding: 0;
	}
	.select__drop-link {
		display: flex;
		align-items: center;
		padding: 8px 12px;
		width: 100%;
		cursor: pointer;
		transition: 0.2s;
	}
}
.select--internal {
	.select__drop-item {
		display: block;
		width: 100%;
		padding: 10px 12px;
	}
}

.select__arrow .icon-arrow2 {
	display: flex;
}
.react-datepicker-popper {
	z-index: 100;
}

.table--pools .token-box {
	cursor: auto;
}

.button--event {
	pointer-events: none;
}
.popup--center {
	text-align: center;
}
.padding--top {
	padding-top: 10px;
}
.item--padding {
	padding-right: 20px;
}

.arrow--position {
	display: flex;
	right: 15px;
	position: absolute;
	pointer-events: none;
}
.arrow--position svg {
	height: 16px;
	width: 16px;
}
.current--position {
	position: relative;
}

.explore-blocks {
	position: relative;
}

.block-counters__progress-item {
	width: 0;
	transition: all 0.3s ease-in-out;
}

.block-details svg path {
	stroke: #8e8ea9;
}

.text--align {
	text-align: center;
}

.select__drop-item:hover {
	background: transparent;
}

.table--address-detail-tokens .tr {
	grid-template-columns: 4fr 1fr 2fr 1.2fr 2.5fr;
}

.select__arrow {
	display: flex;
	flex: 0 0 16px;
	justify-content: flex-end;
	max-width: 16px;
	svg path {
		stroke: #c0c0cf;
	}
}

.input-table-td--fz12 p,
.transactions-swap__count-text {
	overflow: hidden;
	text-overflow: ellipsis;
}

.block-details-value {
	&--panding,
	&--confirmed {
		p {
			color: #3fdbb1;
		}
		.block-details__icon svg path {
			stroke: #3fdbb1;
		}
	}
	&--failed {
		p {
			color: #fd3232;
		}
		.block-details__icon svg path {
			stroke: #fd3232;
		}
	}
}

.info-status--type2.info-status {
	&--failed {
		background: #fd3232;
	}
	&--pending {
		background: orange;
	}
}

.icon-earn {
	border-radius: 50%;
}

.buttom-level {
	margin-left: 15px;
	padding: 5px 10px;
}

.button--width{
	width: 180px;
}

@media screen and (max-width: 1199px) {
	.nav-block {
		z-index: 10001;
	}
	.nav-item:hover .dropdown {
		display: none;
		z-index: -100;
	}
	.nav-item.active {
		.dropdown {
			display: flex;
			z-index: 10;
		}
	}
}

@media screen and (max-width: 765px) {
	.transactions-info {
		&__transfer {
			width: 100%;
		}
		&__center {
			flex-direction: column;
			gap: 5px;
		}
		&__wallet {
			display: block;
		}
	}
	.transactions-swap {
		&__wallets {
			margin-right: 0;
			overflow: hidden;
		}
	}
}
@media screen and (max-width: 548px) {
	.block-card {
		text-align: center;
	}
}

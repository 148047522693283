body {
	.explore-home-section {
		.container {
			.token {
				.block-item {
					max-width: 570px;
				}
			}

			.block-pagination--table {
				padding: 13px 0;
				border: none;
				border-top: none;
				border-radius: 0 0 0 0;
				background-color: transparent;
			}
		}
	}
}

.select--wallet-tokens {
	width: 150px;

	.select__drop {
		width: 345px;
		padding: 0;
	}

	.select__drop-scroll {
		max-height: 380px;
	}

	.select__drop-search {
		padding: 10px 16px;
		border-bottom: 1px solid var(--border-main);
	}
}

html {
	body {
		.explore-block--tokens {
			.block-counters__text--flex {
				align-items: initial;
			}
		}
		.explore-block {
			.tooltip {
				width: max-content;
			}
		}
	}
}

.tokens {
	.breadcrumbs__item--hide-arrow:after {
		display: none;
	}
}
.tokens-breadcrumbs-button {
	display: inline-block;
}
.breadcrumbs__hex {
	display: inline-flex;
	svg {
		width: 18px;
	}
}

.explore-blocks--tokens {
	.block-item:hover {
		cursor: pointer;
	}
}

.block-number--transfer {
	background: var(--transfer-blue);
}

.token-box__text--tooltip {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 4px;
	margin-bottom: 4px;
}
.token-box__icon {
	display: flex;
	img {
		border-radius: 50%;
	}
}

.token-box__icon-3 {
	img {
		object-fit: cover;
		border-radius: 50%;
	}
}

.breadcrumbs__item {
	a {
		color: inherit;
	}
}

.token-block__icon {
	flex-shrink: 0;
}

.token-btn-icon svg path {
	stroke: #fff;
}

button.token-box {
	text-align: left;
}
.token-box{
	justify-content: center;
}

.block-details-value--type2 {
	p {
		cursor: pointer;
		overflow: inherit;
	}
	svg path {
		stroke: var(--main-color);
	}
}

.text-align__right {
	text-align: right;
}

.error-message {
	color: red;
}
@media screen and (max-width: 1185px) {
	body {
		.explore-home-section {
			.container {
				.token {
					.block-item {
						max-width: 100%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.table-btns {
		gap: 10px;
	}
	.table-top__icon {
		margin-left: 0;
	}
}
